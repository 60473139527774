<template>
  <div v-bind:style="styles">
    <div id="page-content">
      <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top: 0px;">Admission Management</h1><button type="button" class="backbtn"  @click="previousRoute"><i class="fa fa-arrow-left"></i></button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Admission Management
       </h4>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
           
              <!-- <div
                class="widjethdr dispflex"
                style="border-top: 1px solid #e5e5e5"
              >
              <div style="width: auto;"><span class="iconsect" style="padding: 0px;"> <img
                    alt=""
                    class="avatar" style="width: 42px;height: 42px;margin:0"
                    src="../../assets/img/img_avatar.png"
                  /></span><h1> STUDENT PROFILE</h1></div>
                
               
              </div> -->
              <div
              class="widjethdr dispflex"
              
            >
            <div style="width: auto;"><span class="iconsect" style="padding: 0px;"> <img
                    alt=""
                    class="avatar" style="width: 42px;height: 42px;margin:0"
                    src="../../assets/img/img_avatar.png"
                  /></span><h1> STUDENT PROFILE</h1></div>
             
                
              <div class="mt-1 flexitm">
                <!-- <button class="btn gry_bg">
                      <i class="fas fa-search"></i>
                    </button> -->
                    <!-- <div class="mt-1" v-if="currentTab == 0"> -->
                      <!-- <button  v-if="currentTab == 0 && !isEdit"
                  type="button"
                  class="btn btnsml edbtn"
                  @click.prevent="editDetails"
                >
                  <span>Edit</span>
                </button> -->

                <button v-if="currentTab == 0 && !isEdit"
                @click.prevent="editDetails"
                         
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-edit" style="margin-left: 3px;"></i
                          ><span data-title="Edit" class="tooltiptext">Edit</span>
                        </button>

                <button v-if="currentTab == 0 && isEdit"
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="updateDetails" 
                  >
                      <span>Save</span>
                  </button>
                  <button type="button" v-if="currentTab == 0 && isEdit" class="btn btncl" id="clbtn" @click.prevent="editCancel">
                       <span>Cancel</span>
                  </button>
                <!-- <button
                  v-if="currentTab == 0"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click="openTeacherPopUp"
                >
                  <i class="fas fa-user-tie user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Add Teacher</span>
                </button> -->

                <!-- <button
                  v-if="currentTab == 1"
                  class="btn gry_bg tooltipt mpopup twoicn"
                  @click="openUserPoUp"
                >
                  <i class="fas fa-user-tie user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Create User</span>
                </button> -->

                
              </div>
            </div>
           
           
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="tabs"
                  @click="setActiveTab(0)"
                  id="academictab"
                  :checked="currentTab == 0 ? 'checked' : ''"

                />
                <label for="academictab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">STUDENT PROFILE</label>
                <div class="tab">
                
                   <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
                       <div class="form-row">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    :disabled="!isEdit"
                    name="studentFirstName"
                    v-model="contactDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                     :disabled="!isEdit"
                    name="studentFirstName"
                    v-model="contactDetails.middleName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="Middle Name"
                  />
                 
                  <label for="stdFirstName"
                    >Middle Name </label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                     :disabled="!isEdit"
                    name="studentLastName"
                    v-model="contactDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                    placeholder="Last Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          @input="checkROllNo"
                          v-validate="'required'"
                          type="text"
                           :disabled="!isEdit"
                          name="roll No allocated"
                          v-model="contactDetails.rollNoAllocated"
                          class="form-control form-input"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />

                         <label
                            v-if="errors.first('studentvalidate.roll No allocated') || !isRollNoAvailable"
                            class="vi-error"
                            style="top:-1em"
                             > {{ errors.first('studentvalidate.roll No allocated') ? 'Roll no allocated is required' : 'Roll No already exists. Please use different one.' }} </label
                          >

                        <label for="rnaltid" v-else
                          >Roll No. allocated 
                          <span class="required">*</span>[Prefix Roll No. in <a style="padding-left: 6px;" href="https://app--stage.vidhyaan.com/admission/admissionSettings" target="_blank" >Setting </a>]</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label" style="margin-right: 0%;">
                          <input
                            v-validate="'required'"
                            disabled
                            @input="checkEmail"
                            name="urname"
                            v-model="userDetails.userName"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="User Name"
                            
                          />                
                          <label
                            v-if="errors.first('studentvalidate.urname') || !isEmailIdAvailable"
                            class="vi-error"
                            style="top:-1em"
                             > {{ errors.first('studentvalidate.urname') ? 'UserName is required' : 'UserName already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }} </label
                          >
                      
                          <label for="ptemailid" v-else
                            >User Name <span class="required">*</span></label
                          >
                        </span> 
                        <!-- <span> {{ usernameConcat }}  </span> -->
                      </div>

                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label" v-if="userDetails.isPasswordVerified">
                          <input
                            v-validate="'required'"
                            type="email"
                            :disabled="!isEdit && userDetails.isPasswordVerified"
                            name="ptemail"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                            
                          />
                           <a v-if="userDetails.isPasswordVerified" href="javascript:void(0);" style="color: red;" @click="resetMailId(editId)">Change Primary Email
                         </a>
                         
                        
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top:-1em"
                             > Primary Email Id is required </label
                          >
                      
                          <label for="ptemailid" v-else
                            >Primary Email <span class="required">*</span></label
                          >
                        </span> 
                         <span class="has-float-label" v-else>
                          <input
                            v-validate="'required'"
                            type="email"
                            :disabled="!isEdit"
                            name="ptemail"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                            
                          />
                          
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top:-1em"
                             > Primary Email Id is required </label
                          >
                      
                          <label for="ptemailid" v-else
                            >Primary Email <span class="required">*</span></label
                          >
                        </span> 
                      </div>
                      <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="editId" >
                        <span class="has-float-label" style="box-shadow: 0 1px 0 0 #e5e5e5;">
                         {{ userDetails.isPasswordVerified ? 'Verified' : 'Not Verified' }}
                          <label for="stadrid" 
                            >Parent mail verification status</label
                          >
                        </span>
                      </div>

             
              <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                    name="status"
                    disabled
                    class="form-control form-input"
                    id="enrollmentStatus"
                    v-model="contactDetails.admissionStatus"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in enrollmentStatusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                   
                 
                  <label for="enrollmentStatus" 
                    >Enrollment Status <span class="required">*</span></label
                  >
                </span>
              </div> -->
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="date"
                    :max="minDob"
                    onkeydown="return false"
                    name="DOB"
                      :disabled="!isEdit"
                    v-model="contactDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                    placeholder="Date of Birth"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          v-validate="'required'"
                          type="date"
                          :min="contactDetails.dob"
                          :disabled="!isEdit || !contactDetails.dob"
                          onkeydown="return false"
                          name="DOJ"
                          v-model="contactDetails.doj"
                          class="form-control form-input"
                          id="DOJ"
                          placeholder="Date of Joining"
                        />
                        <label
                          v-if="errors.first('studentvalidate.DOJ')"
                          class="vi-error"
                          style="top:-1em"
                          >Date of Joining is required</label
                        >
                      
                      
                        <label for="DOJ" v-else
                          >Date of Joining <span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3" v-if="editId">
                      <!-- max="9999-12-31" -->
                      <span class="has-float-label">
                        <input
                        
                         v-validate="contactDetails.admissionStatus == 'Inactive' ? 'required' : false"
                          type="date"
                          :disabled="!isEdit || !contactDetails.doj"
                          :min="contactDetails.doj"
                          onkeydown="return false"
                          name="Leaving"
                          v-model="contactDetails.dateOfLeaving"
                          class="form-control form-input"
                          id="Leaving"
                          placeholder="Date of Leaving"
                        />
                        <label
                          v-if="errors.first('studentvalidate.Leaving')"
                          class="vi-error"
                          style="top:-1em"
                          >Date of Leaving is required</label
                        >
                        <label for="DOJ" v-else
                          >Date of Leaving <span class="required" v-if="contactDetails.admissionStatus == 'Inactive'">*</span></label
                        >
                      </span>
                    </div>
             
              
                    <div class="form-group input-group col-md-2 pr-md-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 107%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Student Discount?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.isStudentDiscount"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
              <div class="form-group input-group col-md-2 mt-3" >
                <span class="has-float-label" v-if="contactDetails.isStudentDiscount">
                  <input
                   v-validate="'numeric|min:0|max_value:100'"
                    type="number"
                    :disabled="!contactDetails.isStudentDiscount"
                    name="studentDiscountValue"
                    v-model="contactDetails.studentDiscountValue"
                    class="form-control form-input"
                    id="stdLastName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentDiscountValue')"
                    class="vi-error"
                    style="top:-1em"
                    >Student Discount is required</label
                  >
                  <label for="stdLastName" v-else
                    >Student Discount(%)<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Siblings?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                        :disabled="!isEdit"
                      v-model="contactDetails.siblings"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
 
              <fieldset class="accordion-group-container">
                  <div >
                 
                  <label v-if="contactDetails.siblings"
                    style="margin: 0"
                    for="accordiongroup-3"
                    class="blutitle accordion-label"
                  >
                    SIBLINGS DETAILS
                  </label>
                  <div v-if="contactDetails.siblings" class="form-row">
                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <v-select
                          v-validate="'required'"
                          name="sblgname"
                          :disabled="!isEdit"
                          v-model="contactDetails.siblingName"
                          class="form-control form-input"
                          id="sblgname"
       style="background-color: white;"
                          @input="setSblingRollNo(contactDetails.siblingName)"

                          placeholder="Type Sibling Name"
                          label="label"
                          :options="optionStaffList"
                         
                          :reduce="(label) => label.value"
                          
                        >
                          <span
                            v-if="!showOption"
                            style="display: none !important"
                            slot="no-options"
                          ></span>
                        </v-select>

                        <!-- <select
                          v-validate="'required'"
                          name="sblgname"
                            :disabled="!isEdit"
                          v-model="contactDetails.siblingName"
                          class="form-control form-input"
                          id="sblgname"
                          @change="setSblingRollNo(contactDetails.siblingName)"
                          placeholder="Student Name"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in auvaiableStudentList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.firstName }}  
                          </option>
                        </select> -->
                        <label
                            v-if="errors.first('studentvalidate.sblgname')"
                            class="vi-error"
                            style="top:-1em"
                            >Sibling Name is required</label
                          >
                        <label v-else for="sblgname"
                          >Sibling Name </label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="rollno"
                          disabled
                          v-model="contactDetails.siblingRollNo"
                          class="form-control form-input"
                          id="rollno"
                        />
                        <label for="rollno"
                          >Roll No</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="rollno"
                          disabled
                          v-model="contactDetails.siblingUserId"
                          class="form-control form-input"
                          id="rollno"
                        />
                        <label for="rollno"
                          >UserId</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="number"
                          name="discount"
                            :disabled="!isEdit"
                          v-model="contactDetails.siblingDiscount"
                          class="form-control form-input"
                          id="rollsssno"
                          v-validate="'numeric|min:0|max_value:100'"
                        />
                        <label
                            v-if="errors.first('studentvalidate.discount')"
                            class="vi-error"
                            style="top:-1em"
                            >Sibling Discount (max 100%)</label
                          >
                        <label v-else for="rollsssno"
                          >Sibling Discount(%)</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                         disabled
                          name="discount"
                          v-model="contactDetails.siblingClass"
                          class="form-control form-input"
                          id="rollsssno"
                        />
                        
                        <label for="rollsssno"
                          >Class</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          disabled
                          name="discount"
                          v-model="contactDetails.siblingSection"
                          class="form-control form-input"
                          id="rollsssno"
                        />
                       
                        <label for="rollsssno"
                          >Section</label
                        >
                      </span>
                    </div>
                    
                  </div>
                </div>
               

                <div>
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    CLASS SECTION DETAILS
                  </label>
                  <div class="form-row">
                   
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          @change="setPlanDate(contactDetails.academicYear)"
                          v-validate="'required'"
                          name="AcademicYear"
                          :disabled="!isEdit || !allowEditAcademicYear"
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="contactDetails.academicYear"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in academicYearList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.from }} - {{ item.to }}
                          </option>
                        </select>
                        <label
                    v-if="errors.first('studentvalidate.AcademicYear')"
                    class="vi-error"
                    style="top:-1em"
                    >Academic year is required</label
                  >
                 
                  
                        <label for="acyearid" v-else
                          >Academic Year <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                       
                         <select
                          v-validate="'required'"
                          name="Class"
                          :disabled="!isEdit || !allowEditAcademicYear"
                          class="form-control form-input"
                          id="deptlistid"
                          @change="getSectionList(contactDetails.class)"
                          v-model="contactDetails.class"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in classList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.className }}  
                          </option>
                        </select>
                        <label
                    v-if="errors.first('studentvalidate.Class')"
                    class="vi-error"
                    style="top:-1em"
                    >Class name is required</label
                  >
                 
                 
                        <label for="clname" v-else
                          >Class Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                       
                         <select
                          v-validate="'required'"
                          name="Section"
                          
                          class="form-control form-input"
                          id="deptlistid"
                          :disabled="!isEdit || !contactDetails.class"
                          v-model="contactDetails.classSection"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in sectionList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.name }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('studentvalidate.Section')"
                            class="vi-error"
                            style="top:-1em"
                            >Section is required</label
                          >
                 
                 
                        <label for="clname" v-else
                          >Section<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     
                  </div>
                </div>

                 <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    PARENT DETAILS
                  </label>
                    <div class="form-row">
                       
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                              :disabled="!isEdit"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                     </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                              :disabled="!isEdit"
                            name="fthrname"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required' : false"
                            v-model="contactDetails.fatherFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father First Name <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="fthrlastname"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required' : false"
                            v-model="contactDetails.fatherLastName"
                            class="form-control form-input"
                              :disabled="!isEdit"
                            id="fthrnameid"
                            placeholder="Father Last Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrlastname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Last Name <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                     
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            @input="checkMobile"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="fphno"
                              :disabled="!isEdit"
                            v-model="contactDetails.mobileOne"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Mobile"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.fphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.fphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label>
                        </span>
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="fthrname"
                            v-model="contactDetails.fatherOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                              :disabled="!isEdit"
                            placeholder="Occupation"
                          />
                          
                          <label for="fthrnameid" 
                            >Occupation </label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                              :disabled="!isEdit"
                            name="mfirstName"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required' : false"
                            v-model="contactDetails.motherFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.mfirstName')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother First Name <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="mlastNmae"
                              :disabled="!isEdit"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required' : false"
                            v-model="contactDetails.motherLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Mother Last Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.mlastNmae')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Last Name <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            @input="checkMobile"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="mphno"
                              :disabled="!isEdit"
                            v-model="contactDetails.mobileTwo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Mobile"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.mphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.mphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            v-model="contactDetails.motherOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                              :disabled="!isEdit"
                            placeholder="Occupation"
                          />
                          
                          <label for="fthrnameid" 
                            >Occupation</label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="gfirstName"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required' : false"
                            v-model="contactDetails.guardianFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                              :disabled="!isEdit"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.gfirstName')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Guardian First Name <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="glastname"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required' : false"
                            v-model="contactDetails.guardianLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                              :disabled="!isEdit"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.glastname')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian Last is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Guardian Last Name <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                     
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            @input="checkMobile"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="gphno"
                              :disabled="!isEdit"
                            v-model="contactDetails.mobileThree"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.gphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.gphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label>
                        </span>
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            v-model="contactDetails.guardianOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Occupation"
                              :disabled="!isEdit"
                          />
                         
                          <label for="fthrnameid" 
                            >Occupation </label
                          >
                        </span>
                        
                      </div>
                      
              
                      
                      
                    </div>
                </div>
                  <div>
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    ADDRESS DETAILS
                  </label>
                  <div class="form-row">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Current Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.addressOne"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 1"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.addressTwo"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 2"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.country"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Country"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                            class="form-control form-input"
                            id="gedfdnder"
                              :disabled="!isEdit"
                            v-model="contactDetails.state"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                          <!-- <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.state"
                            class="form-control form-input"
                            id="occid"
                            placeholder="State"
                          /> -->
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.district"
                            class="form-control form-input"
                            id="occid"
                            placeholder="City/Town"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.postalCode"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Pin Code"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                      <span style="width: 100%;" class="pl-1 my-2">
                                 

                                  <span style="color: #6c757d" class="ml-3"
                                    >Permanent address same as current address ?
                                    </span
                                  >
                                  <input
                                    :disabled="!isEdit"
                                    @change="setPermentAddress(contactDetails.isPermentAddress)"
                                    v-model="contactDetails.isPermentAddress"
                                    type="checkbox"
                                  />
                                </span>
                     
                    
                  </div>
                  <div class="form-row" v-if="!contactDetails.isPermentAddress">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Permanent Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.permentaddressOne"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 1"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                              :disabled="!isEdit"
                            name="occ"
                            v-model="contactDetails.permentaddressTwo"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 2"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.permentcountry"
                            class="form-control form-input"
                            id="occid"
                              :disabled="!isEdit"
                            placeholder="Country"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                              :disabled="!isEdit"
                            class="form-control form-input"
                            id="gedfdnder"
                            v-model="contactDetails.permentstate"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                          <!-- <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.state"
                            class="form-control form-input"
                            id="occid"
                            placeholder="State"
                          /> -->
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.permentdistrict"
                            class="form-control form-input"
                            id="occid"
                            placeholder="City/Town"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                              :disabled="!isEdit"
                            v-model="contactDetails.permentpostalCode"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Pin Code"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                    
                     
                    
                  </div>
                </div>
                  <div v-if="editId">
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    OTHER INFORMATION
                  </label>
                  <div class="form-row">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                      :disabled="!isEdit"
                    v-model="contactDetails.bloodGroup"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(blood, index) in bloodGroupList"
                      :key="index"
                      :value="blood"
                    >
                      {{ blood }}
                    </option>
                  </select>
                 
                  <label for="bloodGroup"
                    >Blood Group </label
                  >
                </span>
              </div>
             

                  <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            :disabled="!isEdit"
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="contactDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                         
                          <label for="firstName" 
                            >Gender </label
                          >
                        </span>
                      </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                              :disabled="!isEdit"
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                              :disabled="!isEdit"
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                              :disabled="!isEdit"
                            v-model="contactDetails.category"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Category </label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                              :disabled="!isEdit"
                            v-model="contactDetails.previousSchool"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Previous School  </label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                              :disabled="!isEdit"
                            v-model="contactDetails.previousClassPassed"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Previous Class Passed"
                          />
                          <label for="relid"
                            >Previous Class Passed </label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >RTE Quota</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                        :disabled="!isEdit"
                      v-model="contactDetails.isrtequota"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Disability</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                        :disabled="!isEdit"
                      v-model="contactDetails.isDisability"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
                    
                  </div>
                </div>
              </fieldset>
            </div>
                    </form>         
                   <div class="widjetfooter text-center">
              <div class="dk_iconsml">
                  
                <!-- <button  v-if="!isEdit"
                  type="button"
                  class="btn btnsml edbtn"
                  @click.prevent="editDetails"
                >
                  <i class="fas fa-edit"></i> <span>Edit</span>
                </button> -->
                <div v-if="isEdit">
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click.prevent="updateDetails" 
                  >
                      <span>Save</span>
                  </button>
                  <button type="button" class="btn btncl" id="clbtn" @click.prevent="editCancel">
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
              <!--<button type="button" class="btn btncl ">Cancel</button>-->
            </div>
                </div>
                <input type="radio" v-if="checkPermission('Fee Management')" @click="setActiveTab(1)" name="tabs" id="departtab"  :checked="currentTab == 1 ? 'checked' : ''"/>
                <label style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" for="departtab" v-if="checkPermission('Fee Management')">FEE PAYMENT </label>
                <div class="tab" v-if="checkPermission('Fee Management')">
                  <div class="widjetcontent mt-1 tbllist">
                    <div>
                      <div class="crtfeeplancont">
                        <div
                          class="widjethdr dispflex"
                          style="align-items: baseline; padding: 10px 0"
                        >
                          <div style="width: 20%">
                            <span class="has-float-label">
                              <select
                                class="form-control form-input"
                                id="deptlistid"
                                @change="getSelectRange"
                                v-model="selectAcademicYear"
                              >
                                <option
                                  v-for="(item, index) in academicYearList"
                                  :key="index"
                                  :value="item._id"
                                >
                                  {{ item.from }} - {{ item.to }}
                                </option>
                              </select>
                              <label for="acyearid"
                                >Academic Year
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                        <div class="restable">
                          <div class="resrow resheader">
                            <div class="cell">Fee Type</div>
                            <div class="cell">Plan Name</div>
                            <div class="cell">Fee Amount ($)</div>
                            <div class="cell">Due Date</div>
                            <div class="cell">Fee Status</div>
                            <div class="cell">Action</div>
                          </div>
                          <div
                            class="resrow"
                            v-for="(data, index) in studentFeePlanList"
                            :key="index"
                          >
                            <div class="cell" data-title="Plan Name">
                              {{ data.type }}
                            </div>
                            <div class="cell" data-title="Plan Name">
                              {{ data.planName }}
                            </div>
                            <div class="cell" data-title="Plan Name" v-if="data.type == 'Term-Fees'">
                              {{ data.termFeeSummary.length > 0 ? getTotalFees(data.termFeeSummary) : 0 }}
                            </div>
                            <div class="cell" data-title="Plan Name" v-if="data.type == 'Adhoc-Fees'">
                              {{ data.adhocFeeSummary.length > 0 ? getTotalAdhocFees(data.adhocFeeSummary) : 0 }}
                            </div>

                            <div class="cell" data-title="Plan Name">
                              {{ data.feesEndDate | dateFormat }}
                            </div>
                            <div
                              class="cell"
                              data-title="Plan Name"
                              :style="
                                (data.status == 'Not Paid' || data.status == 'Cancelled' )
                                  ? 'color : darkorange;'
                                  : 'color : lightgreen'
                              "
                            >
                              {{ data.status }}
                            </div>
                            <div class="cell" data-title="Action">
                              <a
                                v-if="data.type == 'Term-Fees'"
                                href="javascript:void(0);"
                                @click.prevent="
                                  viewStudentTerms(
                                    data.studentId,
                                    data.feePlanId,
                                    data.status
                                  )
                                "
                                >View</a
                              >
                              <a
                                v-if="data.type == 'Adhoc-Fees'"
                                href="javascript:void(0);"
                                @click.prevent="
                                  viewStudentAdhocTerms(
                                    data.studentId,
                                    data.adhocFeeId
                                  )
                                "
                                >View</a
                              >
                              <!-- <button
                                             class="btn round orange tooltipt"
                                             >
                                             <i class="fas fa-edit"></i
                                             ><span class="tooltiptext">Edit</span>
                                             </button>
                                             <button class="btn round red tooltipt">
                                             <i class="far fa-trash-alt"></i
                                             ><span class="tooltiptext">Delete</span>
                                             </button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  v-if="checkPermission('Fee Management')"
                  @click="setActiveTab(2)"
                  name="tabs"
                  id="paymentTab"
                  :checked="currentTab == 2 ? 'checked' : ''"

                  
                />
                <label style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" v-if="checkPermission('Fee Management')" for="paymentTab" 
                  >PAYMENT HISTORY
                </label>
                <div class="tab" v-if="checkPermission('Fee Management')">
                     <payment-history></payment-history>
                </div>
                 <input type="radio" v-if="checkPermission('Alerts & Notification')" name="tabs" id="alert" @click="setActiveTab(3)"   :checked="currentTab == 3 ? 'checked' : ''"/>
                <label style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" for="alert" v-if="checkPermission('Alerts & Notification')">ALERT AND NOTIFICATIONS </label>
                <div class="tab" v-if="checkPermission('Alerts & Notification')">
                  <div class="widjetcontent mt-1">
                    <div>
                       <div class="kidswrapper p-3" style="background-color: #efe6e6;" v-for="(data, index) in alertList" :key="index">
                        <div class="row">
                          <div class="col-12">
                            <div class="kids-details-box mb-3" style="display: grid;">
                               Subject         :  {{ data.subject || '' }} <br> Event Type       :  {{ data.eventType || '' }} <br> Date And Time:  {{ data.createdDate | dateAndTimeFormat }}  
                             <br>
                              <div
                                disabled
                                v-html="data.content"
                                style="height: 200px;margin-bottom: 2%;overflow-y: scroll;padding-top: 3%;"
                              >
                              </div>
                              <!-- {{ data.subject }}
                              <textarea
                               v-model="data.content"
                              class="form-control web-form-input"
                              style="min-height:135px;resize:none"
                              id="message"
                              disabled
                              placeholder="Description"
                            ></textarea> -->
                             <br>
                            <span v-for="(link, index) in data.linkList" :key="index" >
                               <a href="javascript:void(0);" class="txtellipsis"   @click.prevent="navigatetolink(link.value)"> Link {{ index + 1 }} : {{ link.name }} 
                               </a>
                            </span>
                             <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in data.documentList" :key="indexDoc">
                            <div class="form-group input-group col-md-4 mt-4">
                              <span class="has-float-label">
                                <input 
                                  v-model="doc.name"
                                  disabled
                                  class="form-control form-input"
                                  id="fpstartdateid"
                                />
                                <label  for="fpstartdateid">File Name</label>
                              </span>
                            </div>
                            <div class="form-group input-group col-md-4 mt-4">
                                                          <a :href="doc.url" target="_blank" >Download </a>

                              <!-- <button 
                                    type="button"
                                    class="btn btnsml"
                                    id="svbtn"
                                    @click.prevent="viewDoc(doc.url)"
                                  >
                                    Download
                                  </button> -->
                            </div>
                        </div>
                            </div>
                           
                          </div>


                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="showSummaryPopUp" no-close-on-backdrop no-close-on-esc>
      <div style="margin-bottom: 5%;">
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-4" style="margin: 0">
            <!-- <span class="close">&times;</span> -->
            <h2>Term Fee Summary List</h2>
          </div>
          <div class="form-group input-group col-md-3 no-print">
            <a
              style="padding-left: 6px;"
              href="javascript:void(0);"
              @click.prevent="openSiblingDisountPopUp"
              v-if="!studentSibilingDetails.isSiblingDiscountApplied && currentStudentStatus != 'Cancelled'"
              >Eligible for Sibling Discount
            </a>
          </div>
          <div class="form-group input-group col-md-3 no-print">
            <a
              style="padding-left: 6px;"
              href="javascript:void(0);"
              @click.prevent="openStudentDisountPopUp"
              v-if="studentDiscountDetails && !studentDiscountDetails.isStudentDiscountEnabledDiscountApplied"
              >Eligible for Student Discount
            </a>
          </div>
          <div class="paynowBtn col-md-1">
              
          </div>
          <div class=" col-md-1 text-right">
         
            <button type="button" aria-label="Close"  @click.prevent="closeSummaryPopUp" class="close">×</button>
          </div>
        </div>
        <div
          class="form-row"
          v-if="
            studentSibilingDetails &&
              studentSibilingDetails.siblings &&
              studentSibilingDetails.isSiblingDiscountApplied && currentStudentStatus != 'Cancelled'
          "
          style="width: 100%;margin-top: 2%;margin-bottom: 2%"
        >
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                :value="studentSibilingDetails.siblings ? 'Yes' : 'No'"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Eligible for Sibling Discount </label>
            </span>
          </div>
          <div class="form-group input-group col-md-2" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                v-model="studentSibilingDetails.siblingDiscount"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Discount</label>
            </span>
          </div>
          <div class="form-group input-group col-md-5" style="margin: 0">
            <span class="has-float-label">
              <v-select
                class="form-control form-input"
                disabled
                label="label"
                name="AssignClasses"
                multiple
                v-model="studentSibilingDetails.toWards"
                :options="towardsAllList"
                :reduce="(label) => label.value"
              ></v-select>
              <label for="stadrid">Towards</label>
            </span>
          </div>
          <div class="form-group input-group col-md-1" style="top: 9px;">
            <!-- <button
              type="button"
              class="btn btncl clsmdl ml-3"
              id="clbtn"
              :disabled="paidAtleastOne.length > 0"
               @click.prevent="openSiblingDisountPopUp"
            >
             Edit
            </button> -->

            <button
                      class="btn round orange tooltipt"
                      :disabled="paidAtleastOne.length > 0"
               @click.prevent="openSiblingDisountPopUp"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
          </div>
          
        </div>
        <div
          class="form-row"
          v-if="
            studentDiscountDetails &&
            studentDiscountDetails.studentDiscountEnabled &&
            studentDiscountDetails.isStudentDiscountEnabledDiscountApplied && currentStudentStatus != 'Cancelled'
          "
          style="width: 100%;margin-top: 2%;margin-bottom: 2%"
        >
          <div class="form-group input-group col-md-2" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                :value="studentDiscountDetails.studentDiscountEnabled ? 'Yes' : 'No'"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Eligible for Student Discount </label>
            </span>
          </div>
          <div class="form-group input-group col-md-3" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                v-model="studentDiscountDetails.studentDiscountAmount"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Discount</label>
            </span>
          </div>
          <div class="form-group input-group col-md-5" style="margin: 0">
            <span class="has-float-label">
              <v-select
                class="form-control form-input"
                disabled
                label="label"
                name="AssignClasses"
                multiple
                v-model="studentDiscountDetails.studentDiscountToWards"
                :options="towardsAllList"
                :reduce="(label) => label.value"
              ></v-select>
              <label for="stadrid">Towards</label>
            </span>
          </div>
          <div class="form-group input-group col-md-2" style="margin: 0">
            <button
                      class="btn round orange tooltipt"
                      :disabled="paidAtleastOne.length > 0"
                     @click.prevent="openStudentDisountPopUp"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
            <!-- <button
              type="button"
              class="btn btncl clsmdl ml-3"
              id="clbtn"
              :disabled="paidAtleastOne.length > 0"
               @click.prevent="openStudentDisountPopUp"
            >
             Edit
            </button> -->
          </div>
          
        </div>

        <br />
        <div class="contact-form">
          <div v-if="Object.keys(studentBillingSummary).length > 0">
            <div class="admintbl mt-1" style="border: 1px solid #e9dede;">
              <div>
                <div class="row cust-bg" style="border: 1px solid #e9dede;margin-right: 0px; margin-left: 0px;">
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Select</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Term Name</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Fees Type</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Invoice Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Invoice Due Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Billing Frequency</div>
                  <!-- <div class="col-1 cell">Refundable</div> -->
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Discount</div>

                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Fee Amount ($)</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Penalty</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Status</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Paid Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;">Total Amount ($)</div>
                </div>
                <div
                  v-for="(data, index) in Object.keys(studentBillingSummary)"
                  :key="index"
                >
                  <div
                    class="row cust-lr" style="border: 1px solid #e9dede;margin-right: 0px; margin-left: 0px;"
                    :style="studentBillingSummary[data][data][0].status == 'Paid' ? 'color : lightgreen;background-color: 00cc00;' : 'color : darkorange'"
                  >
                    <div class="cell chkbox col-1" data-title="Select">
                      <label
                        class="custcheckbox"
                        :style="studentBillingSummary[data][data][0].status == 'Paid' || currentStudentStatus == 'Cancelled' ? 'cursor: no-drop;' : '' "
                      >
                        <input
                          type="checkbox"
                          checked="checked"
                          v-if="studentBillingSummary[data][data][0].status == 'Paid'"
                          disabled
                        />

                        <input
                          v-else
                          type="checkbox"
                          @change="checkSelected(data,studentBillingSummary[data].isSelected,index)"
                          v-model="studentBillingSummary[data].isSelected"
                          :disabled="studentBillingSummary[data][data][0].status == 'Paid' || currentStudentStatus == 'Cancelled'"

                        />
                        <span
                          class="checkmarkchk"
                          :style="
                            studentBillingSummary[data][data][0].status == 'Paid'? 'background-color: #e0d0c3;': '' "
                        ></span>
                      </label>
                    </div>
                    <div class="col-6 cell" style="font-weight: bold;">
                      {{ data }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      $
                      {{ totalFeesTermAmountOnly(data) | currencyFormat }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      $
                      {{ totalFeesTermPenality(data) | currencyFormat }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;font-size: smaller;">
                      {{ studentBillingSummary[data][data][0].status ? studentBillingSummary[data][data][0].status : "-" }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ studentBillingSummary[data][data][0].paidDate ? showDateFormat(studentBillingSummary[data][data][0].paidDate ) : "-" }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold;color: black;"
                    >
                      {{ showFeePlanDiscount == true ? totalFeesTermWithDiscount(data) : (totalFeesTerm(data)) }}
                    </div>
                  </div>
                  <div
                    class="row cust-lr" 
                    v-for="(ter, issndex) in studentBillingSummary[data][data]"
                    :key="issndex"
                  >
                    <div class="col-2 cell" ></div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">{{ ter.feeType ? ter.feeType.name : '' }}</div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">
                      {{ ter.feesStartDate | dateFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">
                      {{ ter.feesEndDate | dateFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">
                      {{ ter.billingFrequency }}
                    </div>
                    <!-- <div class="col-1 cell">
                      {{ ter.isRefundable ? "yes" : "No" }}
                    </div> -->
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">
                      {{ ter.isDiscount ? showFeePlanDiscount == true ? getdiscountAmountTerm(ter.amount) : '-' : "-"  }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">
                      {{ ter.amount | currencyFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">
                      -
                      <!-- <span
                        v-if="
                            ter.penaltyAmount > 0 &&
                            ter.feePlan.penalty &&
                            ter.feePlan.penalty.mode == 'Rs($)'
                        "
                      >
                        {{
                          "Rs." + " " + ter.feePlan.penalty.penaltyAmountValue
                        }}
                      </span>
                      <span
                        v-if="
                            ter.penaltyAmount > 0 &&
                            ter.feePlan.penalty &&
                            ter.feePlan.penalty.mode == 'Percentage(%)'
                        "
                      >
                        {{ ter.feePlan.penalty.penaltyPercentageValue + "%" }}
                      </span> -->
                    </div>
                    <div class="col-1 cell" style="font-size: smaller;border: 1px solid #e9dede;">{{ ter.status }}</div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;">
                      {{ ter.paidDate ? showDateFormat(ter.paidDate) : "-" }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;"></div>
                  </div>
                </div>
                <div style="border-style: groove;margin-top: 2%;">
                  <div class="row" v-if="showFeePlanDiscount">
                    <div class="col-4 cell" style="font-weight: bold;"></div>

                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getDiscountAmount || 0 }}
                    </div>
                    <!-- <div class="col-1 cell" style="font-weight: bold;"></div> -->
                    <div
                      class="col-4 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                    Total Amount With Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ totalSumAmount | currencyFormat }}
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-4 cell" style="font-weight: bold;"></div>

                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getDiscountAmount }}
                    </div>
                    <!-- <div class="col-1 cell" style="font-weight: bold;"></div> -->
                    <div
                      class="col-4 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                    Total Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ totalSumAmount | currencyFormat }}
                    </div>
                  </div>
                  <!-- <div class="row" v-else>
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Total Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ totalSumAmount | currencyFormat }}
                    </div>
                  </div> -->
                  
                  <!-- <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getDiscountAmount }}
                    </div>
                  </div> -->
                   <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Penalty Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ getPenaltyAmountOnly }}
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Sibling Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      <input
                        v-model="adminOverrideDiscount"
                        type="text"
                        name="stadr"
                        class="form-control form-input"
                        id="stadrid"
                      />
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Student Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      <input
                        v-model="adminOverrideStudentDiscount"
                        type="text"
                        name="stadr"
                        class="form-control form-input"
                        id="stadrid"
                      />
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                    Payable Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;">
                      {{ grandTotalAmount | currencyFormat }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="alert alert-info mt-3" style="padding: 0.35rem 0.65rem">
              <ul style="margin: 0">
                <li>
                  {{
                    planDetails.discount && planDetails.discount.mode
                      ? planDetails.discount.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.discount.discountAmountValue
                        : planDetails.discount.discountPercentageValue + "%"
                      : "0%"
                  }}
                  discount is applicable if paid initially for full term within
                  the due date
                </li>
                <li>
                  {{
                    planDetails.penalty && planDetails.penalty.mode
                      ? planDetails.penalty.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.penalty.penaltyAmountValue
                        : planDetails.penalty.penaltyPercentageValue + "%"
                      : "0%"
                  }}
                  Late Fee charges are levied if the fees are not paid within
                  the due date
                </li>
                <li>
                  Sibling Discount will be applicable if the student is eligible
                  and it will reflect in Student Fee Summary Section
                </li>
                <li>
                  *Discount & other charges are applied/included in Final
                  Billing Summary
                </li>
              </ul>
            </div>
            <div class="widjet mt-4 w-100" v-if="checkTermPayment && currentStudentStatus != 'Cancelled'">
              <div class="widjethdr dispflex">
                <div style="width:auto">
                  <span class="iconsect blubg" style="padding-left:9px;"
                    ><i class="far fa-list-alt"></i
                  ></span>
                  <h1>Payment</h1>
                </div>
              </div>
            
             
              <div
                class="widjetcontent p-3"
              >
                <div class="form-row">
                  <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                     
                    <span class="has-float-label">
                      <select
                        class="form-control form-input"
                        name="PlanType"
                        @change="clearValues(paymentDetails.type)"
                        v-model="paymentDetails.type"
                      >
                        <option :value="null">--Select--</option>
                        <option
                          v-for="(item, index) in payMenttypes"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                     <label for="paymenttypelistid"
                        >Payment Type <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cheque'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="chqno"
                        v-model="paymentDetails.chequeNo"
                        class="form-control form-input"
                        id="chqnoid"
                        placeholder="Cheque No."
                      />
                      <label for="chqnoid"
                        >Cheque No. <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Demand Draft'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="ddno"
                        v-model="paymentDetails.demandDraft"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Demand Draft"
                      />
                      <label for="ddnoid"
                        >Demand Draft <span class="required">*</span></label
                      >
                    </span>
                  </div>
                 
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cash'"
                  >
                    <span class="has-float-label">
                      <input
                        type="text"
                        name="ddno"
                        disabled
                        v-model="paymentDetails.amount"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Amount"
                      />
                      <label for="ddnoid"
                        >Amount <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <!-- <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cash'"
                  >
                    <span class="has-float-label" v-if="schoolDetails.convenienceFee == 'Rs($)'">
                      <input
                        type="text"
                        name="ddno"
                        disabled
                        v-model="schoolDetails.convenienceFeeAmount"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Amount"
                      />
                      <label for="ddnoid"
                        >convenienceFee {{ schoolDetails.convenienceFee }} <span class="required">*</span></label
                      >
                    </span>
                    <span class="has-float-label" v-else>
                      <input
                        type="text"
                        name="ddno"
                        disabled
                        v-model="schoolDetails.convenienceFeePercentage"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Amount"
                      />
                      <label for="ddnoid"
                        >convenienceFee {{ schoolDetails.convenienceFee }} <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    v-if="paymentDetails.type == 'Cash'"
                  >
                    <span class="has-float-label" v-if="schoolDetails.convenienceFee == 'Rs($)'">
                      <input
                        type="text"
                        name="ddno"
                        disabled
                        :value="addConvienceFeeAmount(totalSumAmount)"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Amount"
                      />
                      <label for="ddnoid"
                        >Total Amount <span class="required">*</span></label
                      >
                    </span>
                    <span class="has-float-label" v-else>
                      <input
                        type="text"
                        name="ddno"
                        disabled
                        :value="addConvienceFeeAmount(totalSumAmount)"
                        class="form-control form-input"
                        id="ddnoid"
                        placeholder="Amount"
                      />
                      <label for="ddnoid"
                        >Total Amount <span class="required">*</span></label
                      >
                    </span>
                  </div> -->


                  <div class="form-group input-group col-md-3 mt-3">
                    <button
                      @click.prevent="paymentConfirm"
                      type="button"
                      :disabled="!paymentDetails.type || (paymentDetails.type == 'Cheque' && !paymentDetails.chequeNo) || (paymentDetails.type == 'Demand Draft' && !paymentDetails.demandDraft) || (paymentDetails.type == 'Cash' && !paymentDetails.amount)"
                      class="btn btnsml"
                      id="svbtn"
                    >
                      <i class="fas fa-check-circle"></i> <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="showAdhocFeeSummaryPopUp" no-close-on-backdrop no-close-on-esc>
      <div style="margin-bottom: 5%;">
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-11" style="margin: 0">
            <!-- <span class="close">&times;</span> -->
            <h2>Adhoc Fee Summary</h2>
          </div>
          <div class="form-group input-group col-md-1" style="margin: 0">
            <button
              @click.prevent="closeAdhocSummaryPopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
               <span>Close</span>
            </button>
          </div>
        </div>
        <br />
        <div class="contact-form">
          <div
            v-if="
              studentAdhocFeesList.length > 0 &&
                studentAdhocFeesList[0].feeLineItem.length > 0
            "
          >
            <div class="admintbl mt-1 p-3">
              <table border="1">
                <thead>
                  <tr>
                    <td scope="col" rowspan="6">
                      Fee Name<span class="required">*</span>
                    </td>
                    <td scope="col" rowspan="6">
                      Amount<span class="required">*</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in studentAdhocFeesList[0].feeLineItem"
                    :key="index"
                  >
                    <td data-label="Fee Name">
                      <input type="text" disabled v-model="item.name" />
                    </td>
                    <td data-label="Fee Name">
                      <input type="text" disabled v-model="item.netAmount" />
                    </td>
                  </tr>
                  <tr style="background:#e7e7e7;color: #444444;">
                    <td style="color:#2954A2"><b>Total Amount</b></td>
                    <td class="txtrgt">
                      <b>{{ getTotalNetAmount() }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="widjet mt-4 w-100"
              v-if="studentAdhocFeesList[0].status != 'Paid'"
            >
              <div class="widjethdr dispflex">
                <div style="width:auto">
                  <span class="iconsect blubg" style="padding-left:9px;"
                    ><i class="far fa-list-alt"></i
                  ></span>
                  <h1>Payment</h1>
                </div>
              </div>

              <div
                class="widjetcontent p-3"
                style="background-color: darkgrey;"
              >
                <div class="form-row">
                  <div
                    class="form-group input-group col-md-3 pr-md-3 mt-3"
                    
                  >
                    <span class="has-float-label" >
                      <input
                        type="text"
                        name="ddno"
                        v-model="adhocFeeComments"
                        class="form-control form-input"
                        id="ddnoid"
                      />
                      <label for="ddnoid"
                        >Comments<span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div class="form-group input-group col-md-3 mt-3">
                    <button
                      @click.prevent="paymentConfirmForAdhocFee"
                      type="button"
                      :disabled="!adhocFeeComments"
                      style="margin-left: 54%;"
                      class="btn btnsml"
                      id="svbtn"
                    >
                      <i class="fas fa-check-circle"></i> <span>Paid</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="showSibilingDiscountPopUp"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div style="margin-bottom: 5%;">
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-11" style="margin: 0">
            <!-- <span class="close">&times;</span> -->
            <h2>Sibling Discount</h2>
          </div>
        </div>
        <div
          class="form-row"
          v-if="studentSibilingDetails && studentSibilingDetails.siblings"
          style="width: 100%"
        >
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                :value="studentSibilingDetails.siblings ? 'Yes' : 'No'"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Eligible for Sibling Discount </label>
            </span>
          </div>
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <input
                v-model="studentSibilingDetails.siblingDiscount"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Discount(%)</label>
            </span>
          </div>
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <v-select
                class="form-control form-input"
                label="label"
                name="AssignClasses"
                multiple
                v-model="studentSibilingDetails.toWards"
                :options="towardsList"
                :reduce="(label) => label.value"
              ></v-select>
              <label for="stadrid">Towards</label>
            </span>
          </div>
        </div>
        <div class="form-row" v-else style="width: 100%">
          <div class="form-group input-group col-md-12" style="margin: 0">
            <span class="required"
              >* Note: Discount applicable only for student who has
              sibling</span
            >
          </div>
        </div>
      </div>
      <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
        <!-- <div class="dk_icon"></div> -->
        <div class="text-center dk_iconsml">
          <button
            v-if="studentSibilingDetails && studentSibilingDetails.siblings"
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="updateSibilingDiscount"
          >
              <span>Save</span>
          </button>
          <button
            type="button"
            @click.prevent="closeSibilingDiscountPopUp"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Cancel</span>
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="showStudentDiscountPopUp"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div style="margin-bottom: 5%;">
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-11" style="margin: 0">
            <!-- <span class="close">&times;</span> -->
            <h2>Student Discount</h2>
          </div>
        </div>
        <div
          class="form-row"
          v-if="studentDiscountDetails && studentDiscountDetails.studentDiscountEnabled"
          style="width: 100%"
        >
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <input
                disabled
                :value="studentDiscountDetails.studentDiscountEnabled ? 'Yes' : 'No'"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Eligible for Student Discount </label>
            </span>
          </div>
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <input
                v-model="studentDiscountDetails.studentDiscountAmount"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Discount(%)</label>
            </span>
          </div>
          <div class="form-group input-group col-md-4" style="margin: 0">
            <span class="has-float-label">
              <v-select
                class="form-control form-input"
                label="label"
                name="AssignClasses"
                multiple
                v-model="studentDiscountDetails.studentDiscountToWards"
                :options="towardsList"
                :reduce="(label) => label.value"
              ></v-select>
              <label for="stadrid">Towards</label>
            </span>
          </div>
        </div>
        <!-- <div class="form-row" v-else style="width: 100%">
          <div class="form-group input-group col-md-12" style="margin: 0">
            <span class="required"
              >* Note: Discount applicable only for student who has
              sibling</span
            >
          </div>
        </div> -->
      </div>
      <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
        <!-- <div class="dk_icon"></div> -->
        <div class="text-center dk_iconsml">
          <button
            v-if="studentDiscountDetails && studentDiscountDetails.studentDiscountEnabled"
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="updateStudentDiscount"
          >
              <span>Save</span>
          </button>
          <button
            type="button"
            @click.prevent="closeStudentDiscountPopUp"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Cancel</span>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      contactDetails: {
        middleName : "",
        fatherFirstName : "",
        fatherLastName : "",
        fatherOccuption : "",
        motherFirstName : "",
        motherLastName : "",
        motherOccuption : "",
        guardianFirstName : "",
        guardianLastName : "",
        guardianOccuption : "",
        mobileThree: null,
        isPermentAddress: false,
        isrtequota: false,
        isDisability: false,
        permentaddressOne  : "",
        permentaddressTwo : "",
        permentstate : "",
        permentdistrict : "",
        permentpostalCode : "",
        permentcountry : "",
        category : "",
        previousSchool : "",
        previousClassPassed : "",
        siblingUserId: "",
        siblingClass: "",
        siblingSection: "",
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        isStudentDiscount: false,
        studentDiscountValue: "",
        dateOfLeaving: null,
        pointOfContact: "",
        academicYear: null,
        gender: '',
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: "Active",
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: "",
      },
      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
        userName: ''
      },
      paymentDetails: {
        type: null,
        chequeNo: "",
        demandDraft: "",
        amount: null,
        terms: [],
      },
      allowEditAcademicYear: true,
      planDetails: {},
      currentTab: 0,
      studentSibilingDetails: {},
      studentDiscountDetails: {},
      payMenttypes: ["Cash", "Cheque", "Demand Draft"],
      enrollmentStatusList: [
        "Active",
        "Inactive",
        "Submitted", 
        "In Review",
        "Assessment",
        "Approved", 
        "Reject", 
        "Enrolled"
      ],
      pointOfList: [
       'Father',
       'Mother',
       'Guardian'
      ],
      genderList: [
       'Male',
       'Female'
      ],
      adhocFeeComments: '',
      currentStudentStatus: '',
      allStudentList: [],
      minPlanDate: null,
      maxPlanDate: null,
      classList: [],
      prevPhno: '',
      isMobileAvailable: true,
      isEmailIdAvailable: true,
      minDob: null,
      sectionList: [],
      studentList: [],
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
      adminOverrideDiscount: 0,
      adminOverrideStudentDiscount: 0,
      totalSumAmount: 0,
      showFeePlanDiscount: false,
      totalPenaltyAmount: 0,
      isMobileAvailable: true,
      isEmailIdAvailable: true,
      isRollNoAvailable: true,
      grandTotalAmount: 0,
      discountAmount: 0,
      adhocFeeTotalAmount: null,
      studentBillingSummary: {},
      discountAllow: false,
      isEdit: false,
      selectAcademicYear: null,
      academicYearList: [],
      studentFeePlanList: [],
      planId: null,
      adhocFeePlanId: null,
      studentAdhocFeesList: [],
      studentAcademicyear: null,
      prevEmailId: '',
      showOption: false,
      userId: null,
      editId: null,
      schoolDetails: {},
      convenienceFeeTotalAmount: 0,
      alertList: [],
      allSectionList: [],
       stateList: [
         "Andaman and Nicobar Islands",
        "Andhra Pradesh",
         "Arunachal Pradesh",
         "Assam",
         "Bihar",
         "Chandigarh",
         "Chhattisgarh",
         "Dadra and Nagar Haveli",
         "Daman and Diu",
         "Delhi",
         "Goa",
         "Gujarat",
         "Haryana",
         "Himachal Pradesh",
         "Jammu and Kashmir",
         "Jharkhand",
         "Karnataka",
         "Kerala",
         "Lakshadweep",
         "Madhya Pradesh",
         "Maharashtra",
         "Manipur",
         "Meghalaya",
         "Mizoram",
         "Nagaland",
         "Odisha",
         "Puducherry",
         "Punjab",
         "Rajasthan",
         "Sikkim",
        "Tamil Nadu",
        "Telangana",
         "Tripura",
         "Uttar Pradesh",
         "Uttarakhand",
         "West Bengal",
      ]
    };
  },
  created() {
    this.getAcademicYearList();
    this.getClassList()
    this.getStudentSummary();
    this.getAllStudentList();
    this.getAccountDetails();
    this.getAlertList();
    this.getAllSectionList()

  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
      optionStaffList() {
      return this.auvaiableStudentList.map((x) => {
        return {
          // label: x.firstName + " " + x.lastName + " / " + x.userId,
          label: x.firstName + " " + x.lastName,
          value: x._id,
        };
      });
    },
    getDiscountAmount() {
      let selectedCount = 0;
      let totalTermCount = Object.values(this.studentBillingSummary).length;
     
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);
         
        if (selectedList.length == totalTermCount) {
          this.discountAllow = true;
          
          if (selectedList[0].planDiscount.discount) {
             let totalDiscount = 0;

            const getDiscountDetails = selectedList[0].planDiscount.discount;
           
            if(getDiscountDetails.mode){
            if (getDiscountDetails.mode == "Rs($)") {
             
              for (let x of selectedList) {
              const termTotalAmount = this.totalFeesTermDiscountAvaible(x.name);
              totalDiscount = totalDiscount + termTotalAmount;
              }
            

              const amountValue = this.totalSumAmount - (Number(getDiscountDetails.discountAmountValue) * totalDiscount);
              
              this.grandTotalAmount = this.totalSumAmount + this.totalPenaltyAmount;
             // this.grandTotalAmount = this.totalSumAmount;

              if (this.adminOverrideDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
              }
              if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
              }

             // getvalue = ((getvalue - (Number(this.planDetails.discount.discountAmountValue) * checkDiscount)))

              return amountValue ? (this.totalSumAmount - amountValue ) : getDiscountDetails.discountAmountValue;
            } else {
              
             if(getDiscountDetails && getDiscountDetails.discountPercentageValue){

             

             let getvalue = 0

             for (let x of selectedList) {
              const termTotalAmount = this.totalFeesTermAmount(x.name);
              getvalue = getvalue + termTotalAmount;
              }

                const discountValue = (getvalue * (Number(getDiscountDetails.discountPercentageValue) )) / 100;

                this.grandTotalAmount = this.totalSumAmount;
                this.grandTotalAmount = this.grandTotalAmount + this.totalPenaltyAmount;
                if (this.adminOverrideDiscount > 0) {
                  this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
                }
                if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
              }
                return discountValue;
             }else{
              
              this.grandTotalAmount = this.totalSumAmount;
              if (this.adminOverrideDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
              }
              if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
              }
              
              return 0;
             }
            }
            }
          }
          console.log("this.totalSumAmount",this.totalSumAmount)
          console.log("this.totalPenaltyAmount",this.totalPenaltyAmount)

          this.grandTotalAmount = this.totalSumAmount + this.totalPenaltyAmount;
          console.log("this.grandTotalAmount",this.grandTotalAmount)
          if (this.adminOverrideDiscount > 0) {
            this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
          }
          if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
          }
          return 0

       
        } else {


          this.discountAllow = false;
          
          this.grandTotalAmount = this.totalSumAmount + this.totalPenaltyAmount;
          if (this.adminOverrideDiscount > 0) {
            this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
          }
          if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
              }
          return 0;
        }
      }
    },
    getTotalAmount() {
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {
          for (let x of selectedList) {
            finalAmount = this.totalFeesTerm(x.name) + finalAmount;
          }
          return finalAmount;
        }
      }
    },
    
    getPenaltyAmountOnly() {
      this.totalPenaltyAmount = 0
      
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected && x.status != "Paid" );

        if (selectedList.length > 0) {
          for (let x of selectedList) {
           
            finalAmount = this.totalPenaltyAmountPay(x.name) + finalAmount;
          }
          this.totalPenaltyAmount = finalAmount
          return finalAmount
        
        } else {
          this.totalPenaltyAmount = 0
          return 0;
        }
      }
    },
    checkTermPayment() {
      let totalTermCount = Object.values(this.studentBillingSummary).length;
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.status == "Paid");

        if (selectedList.length == totalTermCount) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    auvaiableStudentList(){
        return this.allStudentList.filter( x => x._id != this.$route.params.id)
    },

    towardsList() {
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
     
      return allTermList
        .filter((x) => x.status != "Paid")
        .map((x) => {
          return {
            label: x.name,
            value: x.name,
          };
        });
    },
     paidAtleastOne() {
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
     
      return allTermList.filter((x) => x.status == "Paid");
    },
    towardsAllList() {
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      return allTermList.map((x) => {
        return {
          label: x.name,
          value: x.name,
        };
      });
    },
  },

  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
    setActiveTab(index) {
      this.currentTab = index;
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    getdiscountAmountTerm(data){
      if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
            if(this.planDetails.discount.mode == 'Rs($)'){
           //  let value = `Rs: ${this.planDetails.discount.discountAmountValue}`
             return '$' + " " + this.planDetails.discount.discountAmountValue

            }else{
              return this.planDetails.discount.discountPercentageValue + "%"

            }
          }else{
            return 0
          }
     },
    checkPermission(data){
        const details = this.$store.getters.getTabPermission;
        if(details && details.length > 0){
          if(details.includes(data)){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      },
   
    async getAlertList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/getalertListForstudent?studentId=${this.$route.params.id}`,userData.token);

        if (response.isSuccess) {
          this.alertList = secureUI.secureGet(response.data);
          
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }

      }
    },
    getTotalFees(values){
      let finalAmount = 0;

       if (values.length > 0) {
          for (let x of values) {
            finalAmount = x.amount + finalAmount;
          }
          return finalAmount;
       }
       
    },
    getTotalAdhocFees(values){
      let finalAmount = 0;

       if (values.length > 0) {
          for (let x of values) {
            if(x.feeLineItem.length > 0){
              for(let y of x.feeLineItem){
              finalAmount = y.netAmount + finalAmount;
              }
            }
          }
          return finalAmount;
       }

    },
    getTotalNetAmount() {
      if (this.studentAdhocFeesList[0].feeLineItem.length > 0) {
        let totalAmount = 0;

        for (let x of this.studentAdhocFeesList[0].feeLineItem) {
          if (x.netAmount) {
            totalAmount = totalAmount + Number(x.netAmount);
          }
        }
        this.adhocFeeTotalAmount = totalAmount
        return totalAmount;
      } else {
        return 0;
      }
    },
    checkSelected(term, value, index) {
      Object.values(this.studentBillingSummary).forEach((x, i) => {
        if (value) {
          if (i < index && x.status != "Paid") {
            x.isSelected = true;
          }
        } else {
          if (i > index && x.status != "Paid") {
            x.isSelected = false;
          }
        }
      });
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
       
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected && x.status != "Paid" );
        if(selectedList.length == allTermList.length){
          this.showFeePlanDiscount = true
        }else{
          this.showFeePlanDiscount = false
        }
        if (selectedList.length > 0) {
          if(this.showFeePlanDiscount){
            for (let x of selectedList) {
            finalAmount = this.totalFeesTermPayOnly(x.name) + finalAmount;
           }
          
            let dddtotalSumAmount = finalAmount;

            console.log("dddtotalSumAmount",dddtotalSumAmount)
            this.totalSumAmount = (dddtotalSumAmount - this.getDiscountAmount)
            console.log("dddtotalSumAsssssssssssssmount",this.totalSumAmount)

          }else{

            for (let x of selectedList) {
            finalAmount = this.totalFeesTermPayOnly(x.name) + finalAmount;
           }
          this.totalSumAmount = finalAmount;
        //  this.getSibilingDiscountAmount();

          }
        } else {
          this.totalSumAmount = 0;
        }

       
      }
       this.getSibilingDiscountAmount();
       this.getStudentDiscountAmount();
    },

    getSibilingDiscountAmount() {
      let finalAmount = 0;

      let calculateTermTotalAmount = 0;
      let discountValue = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);
         
        if (selectedList.length > 0) {
          for (let x of selectedList) {
            if (this.studentSibilingDetails.toWards.indexOf(x.name) != -1) {
              const termTotalAmount = this.totalFeesTerm(x.name);
              discountValue = (termTotalAmount * Number(this.studentSibilingDetails.siblingDiscount)) / 100;
              finalAmount = finalAmount + discountValue;
            }
          }
          this.adminOverrideDiscount = finalAmount;
        }else{
          this.adminOverrideDiscount = 0;
        }
      }
    },
    getStudentDiscountAmount() {
      let finalAmount = 0;

      let calculateTermTotalAmount = 0;
      let discountValue = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);
         
        if (selectedList.length > 0) {
          for (let x of selectedList) {
            if (this.studentDiscountDetails.studentDiscountToWards.indexOf(x.name) != -1) {
              const termTotalAmount = this.totalFeesTerm(x.name);
              discountValue = (termTotalAmount * Number(this.studentDiscountDetails.studentDiscountAmount)) / 100;
              finalAmount = finalAmount + discountValue;
            }
          }
          this.adminOverrideStudentDiscount = finalAmount;
        }else{
          this.adminOverrideStudentDiscount = 0;
        }
      }
    },
    clearValues(value) {
      (this.paymentDetails.chequeNo = ""),
        (this.paymentDetails.demandDraft = ""),
        (this.paymentDetails.amount = this.grandTotalAmount);
    },
    clearAdhocValues(value) {
      (this.paymentDetails.chequeNo = ""),
        (this.paymentDetails.demandDraft = ""),
        (this.paymentDetails.amount = this.getTotalNetAmount());
    },
    showDateFormat(value) {
      if (value) {
        const dtf = new Intl.DateTimeFormat("en", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
        const [
          { value: mo },
          ,
          { value: da },
          ,
          { value: ye },
        ] = dtf.formatToParts(new Date(value));

        return `${da}-${mo}-${ye}`;
      } else {
        return "";
      }
    },
    totalFeesTermDiscountAvaible(value){
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value].filter( x => x.isDiscount).length
        // return this.studentBillingSummary[value][value]
        //   .map((o) => o.amount + o.penaltyAmount)
        //   .reduce((a, c) => {
        //     return a + c;
        //   });
      }
    },
    totalFeesTermAmount(value){
      if (this.studentBillingSummary[value][value]) {
      //  return this.studentBillingSummary[value][value].filter( x => x.isDiscount).length
        return this.studentBillingSummary[value][value]
        .filter(x => x.isDiscount)
        .reduce((sum, x) => sum + x.amount, 0);
      }
    },
   

    totalFeesTerm(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount + o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
      //   let totalTermCount = Object.values(this.studentBillingSummary).length;
     
      // const allTermList = [];

      // for (let x of Object.values(this.studentBillingSummary)) {
      //   allTermList.push(x);
      // }

      // if (allTermList.length > 0) {
      //   const selectedList = allTermList.filter((x) => x.isSelected);
         
      //   if (selectedList.length == totalTermCount) {
         
      //     let getvalue = 0
      //   for(let x of this.studentBillingSummary[value][value]){
      //     getvalue = (getvalue + x.amount)
      //   }

      //   const checkDiscount = this.studentBillingSummary[value][value].filter( x => x.isDiscount).length

      //    if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
      //       if(this.planDetails.discount.mode == 'Rs($)'){
      //         getvalue = ((getvalue - (Number(this.planDetails.discount.discountAmountValue) * checkDiscount)))
      //         return getvalue
      //       }else{
      //         let getDiscountValue =  (getvalue * (Number(this.planDetails.discount.discountPercentageValue) * checkDiscount )) / 100;
      //         return getvalue - getDiscountValue;
      //       }
            
      //     }
      //   } else {
      //     return this.studentBillingSummary[value][value]
      //     .map((o) => o.amount + o.penaltyAmount)
      //     .reduce((a, c) => {
      //       return a + c;
      //     });
      //   }
      // }

        // return this.studentBillingSummary[value][value]
        //   .map((o) => o.amount + o.penaltyAmount)
        //   .reduce((a, c) => {
        //     return a + c;
        //   });

         


      } else {
        return 0;
      }
    },
    totalFeesTermWithDiscount(value) {
      if (this.studentBillingSummary[value][value]) {
        // return this.studentBillingSummary[value][value]
        //   .map((o) => o.amount + o.penaltyAmount)
        //   .reduce((a, c) => {
        //     return a + c;
        //   });
        let totalTermCount = Object.values(this.studentBillingSummary).length;
     
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);
         
        if (selectedList.length == totalTermCount) {
          let getValuetotalAmount = 0
          let getvalue = 0
        for(let x of this.studentBillingSummary[value][value]){
          if(x.isDiscount){
            getvalue = (getvalue + x.amount)

          }else{
            getValuetotalAmount = (getValuetotalAmount + x.amount)
          }
        }

        const checkDiscount = this.studentBillingSummary[value][value].filter( x => x.isDiscount).length

         if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
            if(this.planDetails.discount.mode == 'Rs($)'){
              getvalue = ((getvalue - (Number(this.planDetails.discount.discountAmountValue) * checkDiscount) ))
              return getvalue + getValuetotalAmount
            }else{
              let getDiscountValue =  (getvalue * (Number(this.planDetails.discount.discountPercentageValue) )) / 100;
              return ((getvalue - getDiscountValue)  + getValuetotalAmount);
            }
            
          }else{
            return this.studentBillingSummary[value][value]
          .map((o) => o.amount + o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
          }
        } else {
          return this.studentBillingSummary[value][value]
          .map((o) => o.amount + o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
        }
      }

        // return this.studentBillingSummary[value][value]
        //   .map((o) => o.amount + o.penaltyAmount)
        //   .reduce((a, c) => {
        //     return a + c;
        //   });

         


      } else {
        return 0;
      }
    },
    
    totalFeesTermPayOnly(value) {
       if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    totalPenaltyAmountPay(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    totalFeesTermAmountOnly(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    totalFeesTermPenality(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    async paymentConfirm() {
      const allTermList = [];
      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {
          const terms = selectedList.map((x) => x.name);
          this.paymentDetails.terms = terms;
          this.paymentDetails["actionType"] = 'School Admin'
          this.paymentDetails["razorPayPaid"] = {}
          this.paymentDetails['planDetails'] = this.planDetails
          this.paymentDetails['sibiling'] = this.studentSibilingDetails

          this.updateStudentTerms();
        } else {
          this.$toasted.error("Please select terms");
        }
      }
    },
     async getAllStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/contact/getStudentList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.allStudentList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async checkROllNo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

        let allowDupUser = true;

        if (allowDupUser) {

        this.contactDetails.rollNoAllocated = this.contactDetails.rollNoAllocated.trim()

        const response = await ViService.viGetOnly(`/common/checkApiNameWithAccount?cName=Contact&rollNoAllocated=${this.contactDetails.rollNoAllocated}&accountId=${userData.userDetails.account._id}`);
        
        if (response.isSuccess) {
          this.isRollNoAvailable = response.isApiNameAvailable
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }

        
        } 
      }
    },
    async checkMobile() {
        let allowDupUser = true;

        if (this.editId) {
          if ( this.userDetails.phoneNo == this.prevPhno) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {

          this.userDetails.phoneNo = this.userDetails.phoneNo.trim()
          this.userDetails.phoneNo = Number(this.userDetails.phoneNo)
          
           const response = await ViService.viGetOnly(`/common/checkApiName?cName=User&phoneNo=${this.userDetails.phoneNo}`);

          if (response.isSuccess) {
              this.isMobileAvailable = response.isApiNameAvailable
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
            }
            }
    },
    async paymentConfirmForAdhocFee() {
      if (this.adhocFeePlanId) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          
          let obj = {
                amount: this.adhocFeeTotalAmount,
                razorPayPaid : {},
                actionType : 'School Admin',
                academicYear: this.studentAcademicyear,
                academicYearValue: this.contactDetails.academicYear
          }

          const response = await ViService.viXPut(
            `/feesManagement/updateStudentsAdhocFeePlan/${this.$route.params.id}/${this.adhocFeePlanId}/${userData.userDetails.account._id}`,
            obj,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeSummaryPaymentPopUp();
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    async updateSibilingDiscount() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.studentSibilingDetails.isSiblingDiscountApplied = true;
       
        const response = await ViService.viXPut(
          `/feesManagement/updateStudentSibilingDiscount/${this.$route.params.id}`,
          this.studentSibilingDetails,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getBillingSummaryList();
          this.closeSibilingDiscountPopUp();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async updateStudentDiscount() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.studentDiscountDetails.isStudentDiscountEnabledDiscountApplied = true;
       
        const response = await ViService.viXPut(
          `/feesManagement/updateStudentDiscount/${this.$route.params.id}`,
          this.studentDiscountDetails,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getBillingSummaryList();
          this.closeStudentDiscountPopUp();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    
    async updateStudentTerms() {
      if (this.planId) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          
          this.paymentDetails['totalSumAmount'] = this.totalSumAmount
          this.paymentDetails["convenienceFeeTotalAmount"] = this.convenienceFeeTotalAmount;
          this.paymentDetails['getDiscountAmount'] = this.getDiscountAmount
          this.paymentDetails['getPenaltyAmountOnly'] = this.getPenaltyAmountOnly
          this.paymentDetails['adminOverrideDiscount'] = this.adminOverrideDiscount
          this.paymentDetails['grandTotalAmount'] = this.grandTotalAmount
          this.paymentDetails['academicYear'] = this.studentAcademicyear
          this.paymentDetails['academicYearValue'] = this.contactDetails.academicYear
          
          const response = await ViService.viXPut(
            `/feesManagement/updateStudentsTermsAndFeePlan/${this.$route.params.id}/${this.planId}/${userData.userDetails.account._id}`,
            this.paymentDetails,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeSummaryPaymentPopUp();
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    addConvienceFeeAmount(value){
      if(this.schoolDetails.convenienceFee){
        if(this.schoolDetails.convenienceFee == 'Rs($)'){
           const totalSumAmount = this.totalSumAmount + Number(this.schoolDetails.convenienceFeeAmount)
           this.convenienceFeeTotalAmount =  totalSumAmount
           return  totalSumAmount
        }else{
           const penaltyValue = (this.totalSumAmount * this.schoolDetails.convenienceFeePercentage) / 100 ;

           this.convenienceFeeTotalAmount =  penaltyValue + this.totalSumAmount
           return  this.convenienceFeeTotalAmount

        }
       
      }
     
      
    },
    addConvienceFeeForAdhocAmount(value){
      if(this.schoolDetails.convenienceFee){
        if(this.schoolDetails.convenienceFee == 'Rs($)'){
           const totalSumAmount = this.adhocFeeTotalAmount + Number(this.schoolDetails.convenienceFeeAmount) 
           this.convenienceFeeTotalAmount =  totalSumAmount
           return  totalSumAmount
        }else{
           const penaltyValue = (this.adhocFeeTotalAmount * this.schoolDetails.convenienceFeePercentage) / 100 ;
           this.convenienceFeeTotalAmount =  this.adhocFeeTotalAmount + penaltyValue
           return  this.convenienceFeeTotalAmount

        }
       
      }
     
      
    },
    async checkEmail() {

        let allowDupUser = true;

        if (this.editId) {
          if ( this.userDetails.email == this.prevEmailId) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser){

          this.userDetails.email = this.userDetails.email.trim()

          this.userDetails.email = this.userDetails.email.toLowerCase();

          const response = await ViService.viGetOnly(`/common/checkApiName?cName=User&email=${this.userDetails.email}`);
          if (response.isSuccess) {
            this.isEmailIdAvailable = response.isApiNameAvailable;
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }

        }
    },
    async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/contact/getClassList",
          userData.token
        );
        if (response.isSuccess) {
          this.classList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    getClassName(classid){
      const classDetails =  this.classList.find((x) => x._id == classid);
      if(classDetails){
        return classDetails.className
      }else{
        return 'No Assigned Class'  
      }
      
    },
    getSectionName(id){
      const sectionDetails =  this.allSectionList.find((x) => x._id == id);
      if(sectionDetails){
        return sectionDetails.name
      }else{
        return ''
      }
    },
    setSblingRollNo(id){
    if(id){
      const sblingDetails =  this.allStudentList.find((x) => x._id == id);
      
      this.contactDetails.siblingRollNo = sblingDetails.rollNoAllocated
      this.contactDetails.siblingUserId = sblingDetails.parent ? sblingDetails.parent.userName : '',
      this.contactDetails.siblingClass = this.getClassName(sblingDetails.class),
      this.contactDetails.siblingSection = this.getSectionName(sblingDetails.classSection)
     
    }else{
      this.contactDetails.siblingRollNo = ''
      this.contactDetails.siblingUserId = "",
      this.contactDetails.siblingClass = "",
      this.contactDetails.siblingSection = ""
    }
     
    },
     async getAllSectionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

        const response = await ViService.viXGet(
          "/class/getAllSectionList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
          this.allSectionList = details.sections
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getSectionList(classId){
      if(classId){
        this.sectionList = []
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/getSectionList?class=${classId}`,userData.token);
        if (response.isSuccess) {
          this.sectionList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    // async getSectionName(classId){
    //   if(classId){
    //     this.sectionList = []
    //    let userData = secureUI.sessionGet("user");

    //   if (!userData) {
    //     this.$toasted.error("Please login and do the action");
    //     this.$router.push('/login');
    //   } else {
    //     const response = await ViService.viXGet(`/contact/getSectionList?class=${classId}`,userData.token);
    //     if (response.isSuccess) {
    //       this.sectionList = secureUI.secureGet(response.data);

    //       this.$toasted.success(response.message);
    //     } else {
    //       this.$toasted.error(response.message);
    //     }
    //   }
    //   }
    // },
    getSelectRange() {
      if (this.selectAcademicYear) {
        this.getStudentFeePlanList('No');
      }
    },
    async updateDetails() {
       let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid && this.isEmailIdAvailable && this.isMobileAvailable) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.viLoader = true;
        const response = await ViService.viXPut(
          `/contact/student/updateSchooladmission/${this.editId}/${this.userId}`,
          {
            contactDetails: this.contactDetails,
            userDetails: this.userDetails,
          },
          userData.token
        );
       
        this.viLoader = null;

        if (response.isSuccess) {
          this.editCancel();
          this.getStudentSummary();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }else{
        this.$toasted.error('Please fill all the required details');
      }
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet("/account/getAccountConfigDetails?accountId=" + userData.userDetails.account._id,userData.token);

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
          
          this.schoolDetails = details
          
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    navigatetolink(value){
      window.open(value, "_blank");
    },
    editDetails(){
     this.isEdit = true;
    },
    editCancel(){
     this.isEdit = false;
     this.prevPhno = '',
     this.prevEmailId = '',
     this.isEmailIdAvailable = true
     this.isMobileAvailable = true
     this.getStudentSummary()
    },
    async getStudentSummary() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getStudentSummary?studentId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          
          this.contactDetails = studentDetails;

          if (this.contactDetails.dob) {
            this.contactDetails.dob = this.contactDetails.dob.substring(0, 10);
          }
          if (this.contactDetails.doj) {
            this.contactDetails.doj = this.contactDetails.doj.substring(0, 10);
          }
          if (this.contactDetails.dateOfLeaving) {
            this.contactDetails.dateOfLeaving = this.contactDetails.dateOfLeaving.substring(0,10);
          }
          this.selectAcademicYear = studentDetails.academicYear._id;
          this.studentAcademicyear = studentDetails.academicYear._id
          this.contactDetails.academicYear = studentDetails.academicYear._id

          this.contactDetails.class = this.contactDetails.class._id;

          if(this.contactDetails.class){
            this.getSectionList(this.contactDetails.class)
          }

          this.contactDetails.classSection = this.contactDetails.classSection._id;
          
          this.userDetails = studentDetails.parent;
          
          this.prevEmailId = this.userDetails.email
          this.prevPhno = this.userDetails.phoneNo

           this.getStudentFeePlanList('yes');
           this.editId = studentDetails._id
          this.userId = studentDetails.parent._id
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async updateFile(docType) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
       
        const docFileDetails = this.$refs[docType].files[0]
      
        const fd = new FormData();
        fd.append(
          "doc",
          docFileDetails,
          docFileDetails.name,
          docFileDetails.actType
        );
        const response = await ViService.viPutOnly(
          `/document/uploadFile/parentPortal/student/photo/${this.$route.params.id}/Contact`,
          fd,
          userData.token
        );
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getStudentSummary();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async viewStudentTerms(studentId, planId,status) {
      if(status == 'Full Paid'){
        this.currentTab = 2
      }else{
        this.currentStudentStatus = status
      this.planId = planId;
      await this.getBillingSummaryList();
      this.$bvModal.show("showSummaryPopUp");
      }
     
    },
   
      setPlanDate(acdyear){
      if(acdyear){

      }else{
        this.contactDetails.doj = null
      }
       this.contactDetails.class = null;
       this.contactDetails.classSection = "";
       this.sectionList = []
    },
    openSiblingDisountPopUp() {
      this.$bvModal.show("showSibilingDiscountPopUp");
    },
    closeSibilingDiscountPopUp() {
      this.$bvModal.hide("showSibilingDiscountPopUp");
    },
    openStudentDisountPopUp() {
      this.$bvModal.show("showStudentDiscountPopUp");
    },
    closeStudentDiscountPopUp() {
      this.$bvModal.hide("showStudentDiscountPopUp");
    },
    
    async viewStudentAdhocTerms(studentId, planId) {
      this.adhocFeePlanId = planId;
      await this.getAdhocFeesSummaryList();
      this.$bvModal.show("showAdhocFeeSummaryPopUp");
    },
    closeSummaryPopUp() {
      this.currentStudentStatus = ''
      this.planId = null;
      this.$bvModal.hide("showSummaryPopUp");
      this.studentBillingSummary = {};
    },
    closeAdhocSummaryPopUp() {
      this.adhocFeePlanId = null;
      this.$bvModal.hide("showAdhocFeeSummaryPopUp");
      this.studentAdhocFeesList = [];
    },
    closeSummaryPaymentPopUp() {
      this.planId = null;
      this.$bvModal.hide("showSummaryPopUp");
      this.studentBillingSummary = {};
      this.paymentDetails.type = null,
      this.paymentDetails.chequeNo = "",
      this.paymentDetails.demandDraft = "",
      this.paymentDetails.amount = null,
      this.paymentDetails.terms = [];
      this.adhocFeePlanId = null;
      this.$bvModal.hide("showAdhocFeeSummaryPopUp");
      this.studentAdhocFeesList = [];
      this.getStudentFeePlanList('No');
    },
    async getBillingSummaryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getBillingSummaryList?studentId=${this.$route.params.id}&feePlanId=${this.planId}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);

          this.studentBillingSummary = studentDetails.summaryList;
          this.studentSibilingDetails = studentDetails.studentData;
          this.studentDiscountDetails = studentDetails.studentData;
          this.planDetails = studentDetails.planDetails || {};

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getAdhocFeesSummaryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getAdhocSummaryDetails?studentId=${this.$route.params.id}&feePlanId=${this.adhocFeePlanId}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          this.studentAdhocFeesList = studentDetails;

          for (let x of this.studentAdhocFeesList) {
            x.isSelected = false;
          }

          

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getStudentFeePlanList(action) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.studentFeePlanList = [];
        const response = await ViService.viXGet(
          `/contact/getStudentFeePlanList?studentId=${this.$route.params.id}&academicYear=${this.selectAcademicYear}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          
          this.studentFeePlanList = studentDetails;

          if(action == 'yes'){
            if(this.studentFeePlanList.length > 0){
              const currentFeePlanDetails = this.studentFeePlanList.filter( x => x.type == 'Term-Fees')
              if(currentFeePlanDetails.length > 0){
                const checkFeeDetails = currentFeePlanDetails[0].status
                this.allowEditAcademicYear = checkFeeDetails == 'Not Paid' || checkFeeDetails == 'Partial Paid' ? false : true
              }else{
               this.allowEditAcademicYear = true
              }
            }else{
              this.allowEditAcademicYear = true
            }
             
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },
  components: {
    "v-select": Select,
    "payment-history" : () => import("./ViAdmissionStudentPaymentHistory.vue")

  },
};
</script>
<style>
/* @media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
} */
</style>
